import React from 'react';
import {Container, Navbar, Nav, Jumbotron, Button, Row, Col, Card, Form} from 'react-bootstrap';
import Subscription from "./settings/subscription";

const FrontPage = (props) => {
    return (
        <>
            <section className="bg-primary text-light py-5">
                <Container>
                    <Row>
                        <Col md={6}>
                            <h1>StonksLab</h1>
                            <p className="lead">A powerful solution for your stock market notification needs.</p>
                            {props.user === undefined && <Button variant="light" size="lg">Get Started</Button>}
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container id="features" className={""}>
                <h2>Key Features</h2>
                <Row>
                    <Col sm={4}>
                        <Card>

                            <Card.Body>
                                <Card.Title>Feature 1</Card.Title>
                                <Card.Text>
                                    Receive marketplace disclosures as notification by the method of your choice:
                                    <ul>
                                        <li>Email</li>
                                        <li>Discord Webhook</li>
                                        <li>Slack Webhook</li>
                                        <li>Teams Webhook</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card>

                            <Card.Body>
                                <Card.Title>Feature 2</Card.Title>
                                <Card.Text>
                                    Receive analysts recommendation changes by the method of your choice:
                                    <ul>
                                        <li>Email</li>
                                        <li>Discord Webhook</li>
                                        <li>Slack Webhook</li>
                                        <li>Teams Webhook</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Container id="about">
                <h2>About Us</h2>
                <p>
                    We are a leading provider of stock market-related notifications and insights. Our SaaS service is designed to keep investors and traders informed about the latest market developments, helping them make informed decisions and stay ahead of the curve.
                </p>
                <p>
                    At our core, we specialize in delivering real-time marketplace disclosures, analyst recommendation changes, and a wide range of other types of notifications relevant to investing. Our platform aggregates data from various sources, curates the most important information, and delivers it to our users in a clear and actionable format.
                </p>
                <p>
                    Our team of experienced financial analysts and data scientists work tirelessly to ensure the accuracy and timeliness of the information we provide. We understand the importance of staying informed in today's fast-paced financial markets, and our goal is to empower our users with the knowledge they need to make confident investment decisions.
                </p>
                <p>
                    Whether you are an individual investor, a professional trader, or a financial institution, our SaaS service offers a comprehensive suite of tools and notifications to enhance your investment strategy. Join us today and experience the power of real-time market insights at your fingertips.
                </p>
            </Container>

            <footer className="text-center mt-5">
                <p>&copy; {new Date().getFullYear()} StonksLab. All rights reserved. &emsp;
                    <a href={"/terms-of-use.html"}>Terms of use.</a> &emsp;
                    <a href={"/privacy-policy.html"}>Privacy policy.</a>
                </p>
            </footer>
        </>
    );
};

export default FrontPage;