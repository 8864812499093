import './App.css';
import {BrowserRouter as Router, Route, Switch, NavLink} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import React, {useEffect, useState} from "react";
import CookieConsent from "react-cookie-consent";
import ProfileSettings from "./settings/ProfileSettings";
import {Amplify, Auth} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import {useHistory} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import awsExports from './aws-exports';
import {Button, Col, Container, NavDropdown, NavItem, Row} from "react-bootstrap";
import LoginForm from "./Login";
import Subscription from "./settings/subscription";
import {useAuthenticator} from "@aws-amplify/ui-react";
import InderesRecommendations from "./inderes/InderesRecommendations";
import FrontPage from "./FrontPage";
import Disclosures from "./disclosures/Disclosures";
import {toast} from "react-toastify";


Amplify.configure(awsExports);

function MainPage() {
    let history = useHistory();
    const {authStatus} = useAuthenticator(context => [context.authStatus]);
    const [user, setUser] = useState(null);

   /* useEffect(() => {
        checkUser();
    }, []);*/

    async function checkUser() {
        try {
            const newUser = await Auth.currentAuthenticatedUser();
            if(user === null) {
                setUser(newUser);
            }
        } catch (error) {
            if(user !== null) {
                setUser(null);
            }
        }
    }

    async function handleLogout() {
        try {
            await Auth.signOut();
            setUser(null);
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    }
    checkUser().then(r => {console.log("user")})
   /* return (
        <FrontPage/>
    )*/

    return (
        <main>
            <Router>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand href={"/"}>StonksLab</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <NavLink to="/" className="nav-link" activeClassName="active">Home</NavLink>
                            <NavLink to="/pricing" className="nav-link" activeClassName="active">Pricing</NavLink>
                            {user !== null && (
                                <NavItem>
                                    <NavLink to="/settings" className="nav-link" activeClassName="active">Settings</NavLink>
                                </NavItem>
                            )}
                        </Nav>
                        <Nav  className={"ms-auto"}>
                            {user !== null && (
                                <NavDropdown title="Disclosures" id="basic-nav-dropdown">
                                    <NavDropdown.Item as={NavLink} to={"/disclosures/market"}>Nasdaq OMX nordic</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={NavLink} to={"/disclosures/inderes"}>Inderes</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={NavLink} to={"/disclosures/shareville"}>Shareville</NavDropdown.Item>
                                </NavDropdown>
                            )}
                            {user ? (
                                <Nav.Item>
                                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                                </Nav.Item>
                            ) : (
                                <NavItem>
                                    <NavLink to="/login" className="nav-link" activeClassName="active">Login</NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

                <Switch>
                    <Route path="/settings">
                        <ProfileSettings/>
                    </Route>
                    <Route path="/disclosures/market">
                        <Disclosures/>
                    </Route>
                    <Route path="/disclosures/inderes">
                        <InderesRecommendations/>
                    </Route>
                    <Route path="/login">
                        <LoginForm/>
                    </Route>
                    <Route path="/pricing">
                        <Subscription/>
                    </Route>
                    <Route path="/">
                        <FrontPage user={user}/>
                    </Route>
                </Switch>
            </Router>
        </main>
    )
}

export default MainPage;

