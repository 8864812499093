//import MaterialTable from "material-table";
//import "./Settings.css";
import {BrowserRouter as Router, Route, Switch, Link} from "react-router-dom";
import Subscription from "./subscription";
import {delete_request, get_stripe_request, post_request, request} from "../HttpClient";
import UpdateEmailForm from "./UpdateEmail";
import ChangePasswordForm from "./ChangePassword";
import LoginForm from "../Login";


import React, {useState} from 'react';
import {Auth} from 'aws-amplify';
import {Form, Button, Alert, Container} from 'react-bootstrap';
import {toast} from "react-toastify";

const ProfileSettings = () => {
    const [password, setPassword] = useState('');
    const [deletionPassword, setDeletionPaswword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handlePasswordChange = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        try {
            await Auth.currentAuthenticatedUser().changePassword(password, newPassword);
            setSuccessMessage('Your password has been changed.');
            setPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    const handleEmailChange = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {email});
            setSuccessMessage('Your email address has been changed.');
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    const handlePersonalDataRequest = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
        const response = await post_request({}, "/api/user/export")
        if('message' in response) {
            toast.info(response.message)
        } else {
            setErrorMessage(response.message);
        }
        setSuccessMessage('Your personal data has been sent.');
    };

    const handleAccountDeletion = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
        const confirmed = window.confirm('Are you sure you want to delete your account?');
        if (confirmed) {
            const response = await delete_request({password: deletionPassword}, "/api/user/delete")
            if('message' in response) {
                toast.info(response.message)
                setSuccessMessage('Your account has been deleted.');
                await Auth.signOut()
            } else {
                setErrorMessage(response.error)
            }
        }
    };

    return (
        <>
            <Container>
                <div className={"mb-5"}>
                    <h3>Change password</h3>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    <Form onSubmit={handlePasswordChange}>
                        <Form.Group controlId="password">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" value={password} onChange={(event) => setPassword(event.target.value)} required/>
                        </Form.Group>
                        <Form.Group controlId="newPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" value={newPassword} onChange={(event) => setNewPassword(event.target.value)} required/>
                        </Form.Group>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control type="password" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} required/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Change Password
                        </Button>
                    </Form>
                </div>
                <div className={"mb-5 border-top border-2"}>
                    <h3>Change email</h3>
                    <Form onSubmit={handleEmailChange}>
                        <Form.Group controlId="email">
                            <Form.Label>New Email Address</Form.Label>
                            <Form.Control type="email" value={email} onChange={(event) => setEmail(event.target.value)} required/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Change Email Address
                        </Button>
                    </Form>
                </div>
                <div className={"mb-5 border-top border-2"}>
                    <h3>Request copy of personal data</h3>
                    <Form onSubmit={handlePersonalDataRequest}>
                        <Button variant="primary" type="submit">
                            Request personal data
                        </Button>
                    </Form>
                </div>
                <div className={"mb-5 border-top border-2"}>
                    <h3>Delete account</h3>
                    <Form onSubmit={handleAccountDeletion}>
                        <Form.Group id="deletionPassword" className={"mb-2"}>
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" value={deletionPassword} onChange={(event) => setDeletionPaswword(event.target.value)} required/>
                        </Form.Group>
                        <Button variant="danger" type="submit">
                            Delete Account
                        </Button>
                    </Form>
                </div>
            </Container>
        </>
    );
};

export default ProfileSettings;