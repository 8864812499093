import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import "./InderesRecommendations.css";
import {baseUrl, delete_request, get_request, post_request, request} from "../HttpClient";
import Button from "react-bootstrap/Button";
import {toast} from "react-toastify";
import {Input} from "@material-ui/core";
import {Container, Form, FormGroup, FormLabel} from "react-bootstrap";
//import { Form, FormGroup, Label, Input, Button } from "reactstrap";

const deliveryOptions = [
    {value: 'email', label: 'Email'},
    {value: 'slack', label: 'Slack'},
    {value: 'webhook', label: 'Webhook'},
    {value: 'teams', label: 'Teams'},
    {value: 'discord', label: 'Discord Webhook'},
]

export default function InderesRecommendations() {

    const [delivery, setDelivery] = useState(deliveryOptions[0].value);
    const [deliveryUrl, setDeliveryUrl] = useState();
    const [enabled, setEnabled] = useState(false)
    const [subscribed, setSubscribed] = useState(false)
    const [anyChanges, setAnyChanges] = useState(false)
    const [selectedCompanies, setSelectedCompanies] = useState([]);

    const [companyOptions, setCompanyOptions] = useState([]);

    const [textValidated, setTextValidated] = useState(false);
    const [marketList, setMarketList] = useState([]);

    function submit(event) {
        const body = {
            id: selectedCompanies,
            DeliveryUrl: deliveryUrl,
            Delivery: delivery
        }
        event.preventDefault();
        if (enabled === false) {
            delete_request(body, '/api/inderes').then(r=> {if (r !== undefined) {
                toast("Saved successfully.")
            }});
        } else {
            post_request(body, '/api/inderes').then(r=> {if (r !== undefined) {
                toast("Saved successfully.")
            }});
        }
        setAnyChanges(false)
    }

    function loadSettings() {
        get_request('/api/inderes').then(r=>{
            if(r === undefined || r.active === false) {
                setEnabled(false)
                setSubscribed(false)
            } else {
                setEnabled(r.enabled)
                setSubscribed(true)
                setDelivery(r.Delivery === null ? "" : r.Delivery)
                setSelectedCompanies((r.id === null || r.id === undefined) ? [] : r.id)
                setDeliveryUrl(r.DeliveryUrl)
                setTextValidated(validateText(r.Delivery, r.DeliveryUrl))
            }
        }).catch(e=>{
            setEnabled(false)
        })
    }

    const handleDisableToggle = () => {
        if(subscribed) {
            setEnabled(!enabled)
            setAnyChanges(true)
        }
    }

    function validateText(deliveryMethod, text) {
        // Perform text validation here, e.g. check for required input
        if(deliveryMethod === 'discord') {
            return (text.startsWith('https://discord.com/api/webhooks/'))
        } else if(deliveryMethod === 'slack') {
            return (text.startsWith('https://hooks.slack.com/services/'))
        } else if(deliveryMethod === 'teams') {
            return (text.includes('webhook.office.com'))
        }
        return false
    }

    const handleTextValidation = () => {
        setTextValidated(validateText(delivery, deliveryUrl))
    }

    useEffect(() => {
        request(baseUrl + '/data/companies.json', undefined, 'get').then(r => {
            let opt = r.map(v => {return {value: v.id, label: v.name}})
            setCompanyOptions(opt)
        })
        loadSettings()
        handleTextValidation()
    }, [])

    const handleTextInputChange = (e) => {
        setDeliveryUrl(e.target.value);
        setAnyChanges(true)
    }
    return (
        <Container>
            <h2>Inderes recommendation change subscriptions</h2>
        <Form onSubmit={submit}>
            <Form.Group controlId="disable-checkbox" className="mb-3">
                <Form.Check type="checkbox" label="Enable inderes recommendation change notifications" checked={enabled && subscribed} disabled={!subscribed} onChange={handleDisableToggle} />
            </Form.Group>
            <Form.Group controlId="multiselectCompanies" className="mb-3">
                <Form.Label>Select companies (leave empty to include all):</Form.Label>
                <Select
                    options={companyOptions}
                    isMulti
                    value={companyOptions.filter(option => selectedCompanies.includes(option.value))}
                    onChange={v => {
                        setSelectedCompanies(v.map(a => a.value))
                        setAnyChanges(true)
                    }}
                    isDisabled={!enabled}
                />
            </Form.Group>
            <Form.Group controlId="selectDelivery" className="mb-3">
                <Form.Label>Select delivery method for recommendation changes:</Form.Label>
                <Select
                    options={deliveryOptions}
                    value={deliveryOptions.filter(option => delivery === option.value)}
                    onChange={v => {
                        setDelivery(v.value)
                        setAnyChanges(true)
                    }}
                    isDisabled={!enabled}
                />
            </Form.Group>
            {(delivery !== "email" && delivery !== undefined && delivery !== "") && <Form.Group controlId="text" className="mb-3">
                {delivery === "discord" && <Form.Label> <a href={"https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"} target="_blank" rel="noreferrer">Paste discord webhook URL here</a> </Form.Label>}
                {delivery === "slack" && <Form.Label> <a href={"https://api.slack.com/messaging/webhooks"} target="_blank" rel="noreferrer">Paste slack webhook URL here</a> </Form.Label>}
                {delivery === "teams" && <Form.Label> <a href={"https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook"} target="_blank" rel="noreferrer">Paste teams webhook URL here</a> </Form.Label>}
                <Form.Control type="text"
                              value={deliveryUrl}
                              onChange={handleTextInputChange}
                              disabled={!enabled}
                              onBlur={handleTextValidation}
                              isInvalid={!textValidated}
                              isValid={textValidated}/>
                <Form.Control.Feedback type="invalid">
                    Invalid webhook url. See the link above for instructions.
                </Form.Control.Feedback>
            </Form.Group>}
            <Button type="submit" disabled={!anyChanges || (subscribed && !textValidated && delivery !== "email" && delivery !== undefined)}>Submit</Button>
        </Form>
        </Container>
    );
}