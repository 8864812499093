import {Authenticator, CheckboxField, useAuthenticator} from "@aws-amplify/ui-react";

export default function LoginForm(){
    return (
        <>
            <Authenticator
                // Default to Sign Up screen
                initialState="signIn"
                components={{
                    SignUp: {
                        FormFields() {
                            const {validationErrors} = useAuthenticator();

                            return (
                                <>
                                    {}
                                    <Authenticator.SignUp.FormFields/>
                                    {}
                                    <CheckboxField
                                        hasError={!!validationErrors.tosAcknowledgement}
                                        name="tosAcknowledgement"
                                        value="yes"
                                        label={<label>I agree with the <a href="/terms-of-use.html">Terms & Conditions</a></label>}
                                    />

                                    {}
                                    <CheckboxField
                                        hasError={!!validationErrors.privacyAcknowledgement}
                                        name="privacyAcknowledgement"
                                        value="yes"
                                        label={<label>I agree with the <a href="/privacy-policy.html">Privacy Policy</a></label>}
                                    />
                                </>
                            );
                        },
                    },
                }}
                services={{
                    async validateCustomSignUp(formData) {
                        if (!formData.tosAcknowledgement) {
                            return {
                                tosAcknowledgement: 'You must agree to the Terms & Conditions',
                            };
                        } else if (!formData.privacyAcknowledgement) {
                            return {
                                privacyAcknowledgement: 'You must agree to the Terms & Conditions',
                            };
                        }
                    },
                }}
            />
        </>
    )
}