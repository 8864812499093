import React, {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {get_request, get_stripe_request, lambdaBase, post_request, post_stripe_request} from "../HttpClient";
import Product from "./product";
import LoginForm from "../Login";
import {toast} from "react-toastify";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Button, Card, Col} from "react-bootstrap";

function Redirect() {
    useEffect(() => {
        //post_stripe_request("/billing_portal_session").then(r=>console.log(r))
        post_request({}, lambdaBase + '/billing_portal_session').then(r => console.log(r))
    }, [])
    return (
        <>
        </>
    )
}

export default function Subscription() {

    const [products, setProducts] = useState([]);
    const [subscriptions, setSubscriptions] = useState(undefined);
    const [selectedPrice, setSelectedPrice] = useState(undefined);
    const {authStatus} = useAuthenticator(context => [context.authStatus]);

    useEffect(() => {
        console.log("request products")
        get_request('/data/products.json').then(r => {
            r = r === undefined ? [] : r;
            setProducts(r)
        })
    }, []);
    if (authStatus === 'authenticated' && subscriptions === undefined) {
        get_stripe_request('/user_products').then(t => {
            t = t === undefined ? [] : t;
            if (t.success === true) {
                setSubscriptions((t.products === null || t.products === undefined) ? [] : t.products)
            }
        })
    }

    function mapCurrency(currency) {
        if (currency === 'eur') {
            return "€"
        } else if (currency === 'usd') {
            return '$'
        }
        return currency
    }

    function BillingPortal() {

        useEffect(() => {
            if (authStatus !== 'authenticated') {
                console.log('not authenticated')
                return
            }
            if (subscriptions !== undefined && subscriptions.length > 0) {
                console.log("Billing portal")
                post_request({}, lambdaBase + '/billing_portal_session').then(r => {
                    if (r.success === true) {
                        window.location.replace(r.url);
                    } else {
                        toast(r.error)
                    }
                }).catch(r => toast(r))
            } else {
                console.log("Subscription flow to " + selectedPrice)
                post_request({"priceId": selectedPrice}, lambdaBase + '/billing_portal_session').then(r => {
                    if (r.success === true) {
                        window.location.replace(r.url);
                    } else {
                        toast(r.error)
                    }
                }).catch(r => toast(r))
            }
        }, []);
        return (
            <>

            </>
        )
    }

    function subscribe(price) {
        setSelectedPrice(price)
    }

    function productPage(subs, hasSubscribed) {
        return (
            <>
                {
                    products.map(x => (
                        <Product title={x.name}
                                 price={(x.prices[0].unit_amount / 100) + mapCurrency(x.prices[0].currency)}
                                 description={x.description.split("\\n")} callback={subscribe} id={x.prices[0].id}
                                 outline={subs.includes(x.id)} hasSubscribed={hasSubscribed}/>
                    ))
                }
            </>
        )
    }

    function CreateSubscriptionPage() {
        const subs = subscriptions !== undefined ? subscriptions : []
        let hasSubscribed = subs.length > 0
        return (
            <>
                <div className="container py-3">
                    <header>

                        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                            <h1 className="display-4 fw-normal">Pricing</h1>
                            <p className="fs-5 text-muted">Transparent and simple pricing with instant access. Cancel at anytime</p>
                        </div>
                    </header>

                    {(selectedPrice !== undefined && authStatus !== 'authenticated') && (<LoginForm/>)}
                    {(selectedPrice !== undefined && authStatus === 'authenticated') && (<BillingPortal/>)}
                    {selectedPrice === undefined && (
                        <main>
                            <div className="row row-cols-1 row-cols-md-3 mb-3 justify-content-center">
                                {productPage(subs, hasSubscribed)}
                            </div>
                        </main>
                    )}
                </div>
            </>
        )
    }

    /*useExternalScripts("https://js.stripe.com/v3/pricing-table.js", undefined)
    return (
        <>
            <stripe-pricing-table pricing-table-id="prctbl_1MGJECDLFBlwduS44FggN8gW"
                                  publishable-key="pk_test_51J7iuJDLFBlwduS4vN3UjJ5u6mw9MeIKX2QADYvJ49dqDQhGFhZhKRrsZlxaO5BDq5alohBemxc6IhxJ4iVQYUe700dAIKRlBK">
            </stripe-pricing-table>
        </>
    )*/

    return (
        <CreateSubscriptionPage/>
    )
    /*
    return (
        <>
            <div className="form">
                {products.map((prod) => {
                    return (
                        <div className="card" key={prod.id}>
                            <section>
                                <h2>{prod.name}</h2>
                                <p>{prod.description}</p>
                                <h3 className={"price"}>{prod.currency === "USD" ? ("$" + prod.unit_amount / 100) : (prod.unit_amount / 100 + "€") + " per " + prod.recurring.interval}</h3>
                                {
                                    (prod.id in priceMap) && priceMap[prod.id].cancel_at_period_end === true &&
                                    <p className={"expiration"}>Subscription expires
                                        at {new Date(parseInt(priceMap[prod.id].current_period_end) * 1000).toLocaleDateString()}</p>
                                }
                                {
                                    (prod.id in priceMap) && priceMap[prod.id].cancel_at_period_end === false &&
                                    <button type="button" onClick={() => billingPortal()}>
                                        Cancel
                                    </button>
                                }
                                {
                                    !(prod.id in priceMap) && Object.keys(priceMap).length > 0 &&
                                    <button type="button" onClick={() => billingPortal()}>
                                        Update
                                    </button>
                                }
                                {
                                    !(prod.id in priceMap) && Object.keys(priceMap).length === 0 &&
                                    <button type="button" onClick={() => subscribe(prod.id)}>
                                        Subscribe
                                    </button>
                                }
                            </section>
                        </div>
                    );
                })}</div>
        </>
    )*/
}