import React from "react";
import {Button, Card, Col} from "react-bootstrap";

export default function Product(props) {
    const outline = props.outline === true ? "btn-warning" : "btn-primary"; //btn-outline-primary
    const hasSubscriptions = props.hasSubscribed !== undefined ? props.hasSubscribed : false
    const buttonText = hasSubscriptions ? (props.outline ? "Cancel" : "Upgrade") : ("Select plan")

    return <>
        <Col sm={4}>
            <Card>
                <Card.Header><h4 className="my-0 fw-normal">{props.title}</h4></Card.Header>
                <Card.Body>
                    <Card.Title className={"display-5"}>{props.price} <small
                        className="text-muted fw-light">/mo</small></Card.Title>
                    <Card.Text>
                        <ul className="list-styled mt-3 mb-4">
                            {props.description.map((line) => (
                                <li>{line}</li>
                            ))}
                        </ul>
                    </Card.Text>
                    <Button variant="primary" className={"w-100 btn btn-lg " + outline} onClick={()=>props.callback(props.id)}>{buttonText}</Button>
                </Card.Body>
            </Card>
        </Col>
    </>
}