import {Auth} from 'aws-amplify';
import {toast} from "react-toastify";

//export const baseUrl = window.location.href.split('/').slice(0, 3).join('/');
//export const baseUrl = 'https://test.stonkslab.com'
//export const baseUrl = ''
export const baseUrl = ''
export const lambdaBase = "/api/stripe";

function parseError(response) {
    if (response.status === undefined) {
        return "Error"
    }
    if (response.body["message"] !== undefined) {
        return response.status + ": " + response.body["message"]
    }
    if (response.body["error"] !== undefined) {
        return response.body["error"]
    }
    return response.status + ": " + JSON.stringify(response.body)
}

export async function request(full_url, header, method) {
    if(header === undefined) {
        header = {}
    }
    //header["x-anti-csrf-token"] = Date.now().toString();
    return fetch(full_url, {
        method: method,
        headers: new Headers(header),
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
        .then(response => {
            if (response.status >= 200 || response.status < 300) {
                return response.body;
            }
            toast.error(parseError(response))
            return undefined;
        })
        .catch(x => {
            console.log(x)
            toast.error(parseError(x))
            return undefined;
        })
}

export function get_request(url) {
    const full_url = url.startsWith('http') ? url : (baseUrl + url);
    return Auth.currentSession().then(r => {
        const header = {'Authorization': 'Bearer ' + r.getAccessToken().getJwtToken()};
        return request(full_url, header, 'get')
    }).catch(r => {
        return request(full_url, {},'get')
    });
}

export function get_stripe_request(url) {
    return Auth.currentSession().then(r => {
        const header = {'Authorization': 'Bearer ' + r.getAccessToken().getJwtToken()};
        return request(baseUrl + lambdaBase + url, header, 'get')
    }).catch(r => {
        return request(baseUrl + lambdaBase + url, {}, 'get')
    });
}
export function post_stripe_request(url, content) {
    return Auth.currentSession().then(r => {
        const header = {'Authorization': 'Bearer ' + r.getAccessToken().getJwtToken()};
        return post_request(baseUrl + lambdaBase + url, 'post')
    }).catch(r => {
        return undefined
    });
}

export function body_request(content, url, method) {
    return Auth.currentSession().then(r => {
        const header = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + r.getAccessToken().getJwtToken()
            //'x-anti-csrf-token': Date.now().toString()
        };
        const requestOptions = {
            method: method,
            headers: header,
            body: JSON.stringify(content)
        };
        return fetch(baseUrl + url, requestOptions)
            .then(r => r.json().then(data => ({status: r.status, body: data})))
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.body;
                }
                toast.error(parseError(response))
                return undefined;
            }).catch((x) => {
                console.log(x);
                toast.error(parseError(x))
                return undefined;
            });
    }).catch(r => {
        console.log(r);
        toast.error(parseError(r))
        return undefined;
    })
}
export function post_request(content, url) {
    return body_request(content, url, 'POST')
}

export function delete_request(content, url) {
    return body_request(content, url, 'DELETE')
}